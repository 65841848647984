


















































































































































import { Component, Mixins } from "vue-property-decorator";
import ChangePasswordComponent from "./ChangePassword.component";
@Component
export default class ChangePassword extends Mixins(ChangePasswordComponent) {}
