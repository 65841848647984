import { Component, Vue } from "vue-property-decorator";
import { VuejsDatatableFactory } from "vuejs-datatable";
import { CHANGE_PASSWORD_AGENT } from "../../../api/account"
import Model from "@/models/Model";

Vue.use(VuejsDatatableFactory);

@Component
class ChangePassword extends Vue {

    private displayShow = true
    public listInput = {
        password: "",
        newPassword: "",
        confirmPassword: ""
    }
    public cookieData: any = Vue.$cookies.get("login")
    private model: Model;
    constructor() {
        super();
        this.model = new Model();
    }
    public password = {
        old: false,
        new: false,
        confirm: false
    }

    private loaderStyle = {
        color: '#CFA137',
        loader: 'spinner',
        width: 145,
        height: 235,
        backgroundColor: '#000000',
        opacity: 0.5,
    }

    async mounted() {
        const loader = this.$loading.show(this.loaderStyle)
        
        this.addRouteName()
        loader.hide()
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public toggleShowPassword(name: string) {
        if (name == 'old') {
            this.password.old = !this.password.old;
        }
        if (name == 'new') {
            this.password.new = !this.password.new;
        }
        if (name == 'confirm') {
            this.password.confirm = !this.password.confirm;
        }
    }

    public changeInputValue(key: string, value: string) {
        this.displayShow = false
        this.listInput[key] = value
        this.displayShow = true
    }

    public async changePassword(event: any) {
        event.preventDefault();
        
        if (this.listInput.newPassword.length < 8) {
            this.$swal({
                text: this.$t('MANAGEMENT.PASSWORD_CHECK').toString(),
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return;
        }

        if (this.listInput.newPassword !== this.listInput.confirmPassword) {
            this.$swal({
                text: this.$t('MANAGEMENT.PASSWORD_BE_SAME').toString(),
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return;
        }
        const data = {
            username: this.cookieData.username,
            password: this.listInput.password,
            newPassword: this.listInput.newPassword,
            confirmPassword: this.listInput.confirmPassword,
        }

        const loader = this.$loading.show(this.loaderStyle)
        
        const changePassword = await CHANGE_PASSWORD_AGENT(data)

        loader.hide()
        
        if (!changePassword.success) {
            
            if(changePassword.error.code === 6006){
                this.$swal({
                    text: this.$t('MANAGEMENT.NEW_PASSWORD_CANT_USE').toString(),
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
            }
            else if(changePassword.error.code === 7001){
                this.$swal({
                    text: this.$t('MANAGEMENT.NEW_PASSWORD_CANT_USE').toString(),
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
            }
            else{
                this.$swal({
                    text: this.$t('MANAGEMENT.OLD_PASSWORD_NOT_CORRECT').toString(),
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
            }
            
            return;
        }

        if (changePassword.success) {
            this.$swal({
                text: this.$t('MANAGEMENT.CHANGE_PASSWORD_SUCCESS').toString(),
                icon: "success",
                timer: 1000,
                showConfirmButton: false,
                timerProgressBar: true,
                onClose: () => {
                    window.location.replace('/change-password');
                }
            });
        }
    }

}
export default ChangePassword